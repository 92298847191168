;(function (global) {

	// ----- Header
	// -------------------------------------------------------------------------

	var
		app = {},
        ismobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase())),
		options = {
			selectors: {
				navigation: '.sidebar',
				navigationToggle: '[data-toggle="offcanvas"]'
			},

			screenSizes: {
				xs: 480,
				sm: 768,
				md: 992,
				lg: 1200
			}
		}
	;


	// ----- Setup
	// -------------------------------------------------------------------------

	function initApp () {
		bindEvents();
		
		setupAJAX();
		setupValidator();
		setupLayout();
		setupNavigation();
		setupForms();
	}

	function bindEvents () {
		$(window, '.wrapper').on('resize', resetLayout);
		$(options.selectors.navigationToggle).on('click', toggleNavigation);

        /*
        $('body').on('click', function(evt) {
            $('[rel="popover"]').each(function () {
                // the 'is' for buttons that trigger popups
                // the 'has' for icons within a button that triggers a popup
                if (!$(this).is(evt.target) && $(this).has(evt.target).length === 0 && $('.popover').has(evt.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
        */ 
	}


	// ----- Initilisation functions
	// -------------------------------------------------------------------------

	function setupAJAX () {
		$(document).ajaxError(function (event, jqxhr, settings, thrownError) {
			if ( thrownError == 'Unauthorized' ) {
				alert('SESSION EXPIRED');
			}
		});
	}

	function setupValidator () {
		jQuery.validator.setDefaults({
			errorContainer: "#errorBox",
			errorLabelContainer: "#errorBox ul",
			errorClass: 'help-block',
			wrapper: "p",
			highlight: function(element) {
				$(element).closest('.form-group').addClass('has-error');
			},
			unhighlight: function(element) {
				$(element).closest('.form-group').removeClass('has-error');
			},
			errorPlacement: function(error, element) {
				var
					type = element.prop('type')
				;

				if (type == 'radio') {
					error.addClass('has-error');
					error.appendTo(element.closest('.radio-group'));

				} else if (type == 'text') {
					if ( element.parent().hasClass('input-group') ) {
						error.insertAfter(element.parent());

					} else {
						error.insertAfter(element);
					}

				} else {
					error.insertAfter(element);
				}
			}
		});
	}

	function setupLayout () {
		resetLayout();
	}

	function setupNavigation () {
		$('li a', $(options.selectors.navigation)).click(function (evt) {
			//Get the clicked link and the next element
			var $this = $(this);
			var checkElement = $this.next();

			//Check if the next element is a menu and is visible
			if ((checkElement.is('.treeview-menu')) && (checkElement.is(':visible'))) {
				//Close the menu
				checkElement.slideUp('normal', function () {
					checkElement.removeClass('menu-open');
					//Fix the layout in case the sidebar stretches over the height of the window
					//resetLayout();
				});
				checkElement.parent('li').removeClass('active');

			//If the menu is not visible
			} else if ((checkElement.is('.treeview-menu')) && (!checkElement.is(':visible'))) {
				//Get the parent menu
				var parent = $this.parents('ul').first();
				//Close all open menus within the parent
				var ul = parent.find('ul:visible').slideUp('normal');
				//Remove the menu-open class from the parent
				ul.removeClass('menu-open');
				//Get the parent li
				var parent_li = $this.parent('li');

				//Open the target menu and add the menu-open class
				checkElement.slideDown('normal', function () {
					//Add the class active to the parent li
					checkElement.addClass('menu-open');
					parent.find('li.active').removeClass('active');
					parent_li.addClass('active');

					//Fix the layout in case the sidebar stretches over the height of the window
					resetLayout();
				});
			}
		
			//if this isn't a link, prevent the page from being redirected
			if (checkElement.is('.treeview-menu')) {
				evt.preventDefault();
			}
		});
	}

	function setupForms () {
        // activate popovers
        $("[rel=popover]").popover();

		$('select').selectpicker();

		$('input').not('.onoffswitch-checkbox').iCheck({
			checkboxClass: 'icheckbox_square-blue',
    		radioClass: 'iradio_square-blue',
            tap: true
		});

        // Datepickers
        if ($.fn.datepicker) {
            $('[rel=datepicker]').each(function () {
                var 
                    $this = $(this),
                    dataDateFormat = $this.attr('data-dateformat') || 'dd/mm/yyyy'
                    dataShowOn = $this.attr('data-show-on') || 'focus',
                    dataStartDate = $this.attr('data-start-date') || ''
                ;

                $this.datepicker({
                    //container: '#container',
                    autoclose: true,
                    todayHighlight: true,
                    orientation: 'auto',
                    format : dataDateFormat,
                    showOn: dataShowOn,
                    startDate: dataStartDate,
                    buttonText: '<i class="datepicker fa fa-calendar"></i>',
                    prevText : '<i class="fa fa-chevron-left"></i>',
                    nextText : '<i class="fa fa-chevron-right"></i>',
                });

                //clear memory reference
                $this = null;
            });
        }
	}


	// ----- Functions
	// -------------------------------------------------------------------------

	function resetLayout () {
		// Get window height and the wrapper height
		var neg = $('.main-header').outerHeight() + $('.main-footer').outerHeight();
		var window_height = $(window).height();
		var sidebar_height = $('.sidebar').height();

		// Set the min-height of the content and sidebar based on the the height of the document.
		if ($('body').hasClass('fixed')) {
			$('.content-wrapper, .right-side').css('min-height', window_height - $('.main-footer').outerHeight());

		} else {
			if (window_height >= sidebar_height) {
				$('.content-wrapper, .right-side').css('min-height', window_height - neg);

			} else {
				$('.content-wrapper, .right-side').css('min-height', sidebar_height);
			}
		}
	}


	function toggleNavigation (evt) {
		// Get the screen sizes
		var screenSizes = options.screenSizes;

		// Enable sidebar push menu
		if ( $(window).width() > (screenSizes.sm - 1) ) {
			$('body').toggleClass('sidebar-collapse');

		// Handle sidebar push menu for small screens
		} else {
			if ( $('body').hasClass('sidebar-open') ) {
				$('body').removeClass('sidebar-open');
				$('body').removeClass('sidebar-collapse')

			} else {
				$('body').addClass('sidebar-open');
			}
		}
	}


	// ----- Expose and launch
	// -------------------------------------------------------------------------

	initApp();
	global._APP = app;

}(window));
