/**
 * A custom jquery plugin that uses the IMS API to lookup addresses
 * from a supplied postcode.
 */

(function (global) {

	var
		PostcodeLookup,
		settings = {
			baseurl: '',
			result: '',
			auto: true
		}
	;

	// ----- Postcode class
	// -------------------------------------------------------------------------

	/**
	 * Create postcode lookup tool
	 */
	PostcodeLookup = function (options) {
		var
			self = this
		;

		this.settings = $.extend(settings, options);
		this.select = null;
		this.data = {};

		// Add a listener to the result container
		$(this.settings.result).on('change', function (evt) {
			_updateFormFields( self.data[$(this).val()] );
		});
	};

	/**
	 * Lookup the postcode
	 */
	PostcodeLookup.prototype.lookup = function (postcode) {
		var
			deferred = new $.Deferred(),
			self = this
		;

		if ( this.settings.auto ) {
			$(this.select).remove();
			this.select = null;
		}

		this.data = {};

		$.get( settings.baseurl + postcode ).done(function (data) {
			if ( self.settings.auto ) {
				// Create or use existing select box
				self.select = _makeSelect( self.settings.result, data );
			}

			// Add the options
			$.each(data, function (i, obj) {
				self.data[obj.id] = obj;

				if ( self.settings.auto ) {
					self.select.append('<option value="'+obj.id+'">'+obj.label+'</option>');
				}
			});

			// Just in case we want to do other things
			deferred.resolve( data );

		}).error(function (xhr, status, type, msg) {

			// Just in case we want to do other things
			deferred.reject( msg );
		});	

		return deferred.promise();
	};


	/**
	 * Make the select box
	 */
	function _makeSelect (elmContainer, data) {
		var
			select
		;

		select = $('<select/>');

		$(elmContainer).append( select );

		/*
		select.select2({
			minimumResultsForSearch : -1,
			allowClear : false,
			width : '100%'
		});
		*/
	
		return select;
	}

	/**
	 * Update the form fields with the address
	 */
	function _updateFormFields (data) {
		$('#address1').val( data.address1 );
		$('#address2').val( data.address2 );
		$('#address3').val( data.address3 );
		$('#town').val( data.town );
		$('#county').val( data.county );
	}


	// ----- Expose
	// -------------------------------------------------------------------------

	global.PostcodeLookup = PostcodeLookup;

}(window));
